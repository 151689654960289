<template>
  <div>
    <div class="block banner bg">
      <div class="container">
        <div class="box">
          <h1>{{$t('solution13')}}</h1>
          <h2>{{$t('solutionBot')}}</h2>
          <p class="sm">{{$t("home67")}}{{$t('solution14')}}</p>
          <el-button type="primary">{{$t('getPlan')}}</el-button>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <h2>
          {{$t('solution15')}}
        </h2>
        <ul class="list1">
          <li class="icon38">
            <h5>{{$t('solution16')}}</h5>
            <p>{{$t('solution17')}}</p>
          </li>
          <li class="icon39">
            <h5>{{$t('solution18')}}</h5>
            <p>{{$t('solution19')}}</p>
          </li>
          <li class="icon53">
            <h5>{{$t('solution20')}}</h5>
            <p>{{$t('solution21')}}</p>
          </li>
          <li class="icon37">
            <h5>{{$t('solution22')}}</h5>
            <p>{{$t('solution23')}}</p>
          </li>
          <li class="icon40">
            <h5>{{$t('solution24')}}</h5>
            <p>{{$t('solution25')}}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <h2>{{$t('solution26')}}</h2>
        <ul class="product-list child">
          <li>
            <div class="img"><img src="@/assets/images/image25.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution27')}}</h4>
              <p>{{$t('solution28')}}</p>
            </div>
          </li>
          <li>
            <div class="img"><img src="@/assets/images/image10.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution29')}}</h4>
              <p>{{$t('solution30')}}</p>
            </div>
          </li>
          <li>
            <div class="img"><img src="@/assets/images/image6.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution31')}}</h4>
              <p>{{$t('solution32')}}</p>
            </div>
          </li>
        </ul>
        <PlanBox />
      </div>
    </div>
  </div>
</template>
<script>
  import PlanBox from '@/components/PlanBox.vue'

export default {
  name: "SolutionBot",
  components: {
    PlanBox
  },
  mounted() {
  },
  data() {
    return {
    }
  },
  metaInfo() {
    return {
      title: '「BoxCDN」'+this.$t('solutionBot')+'-'+ this.$t('home80')
    }
  }
}
</script>
<style lang="scss" scoped>
  .banner {
    background-image: url('~@/assets/images/banner2.jpg');
  }
  .list1 {
    li {
      width: 33.33%;
    }
  }
</style>
